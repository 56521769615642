import * as React from 'react';
import { Link as ScrollLink, animateScroll as scroll } from 'react-scroll';
import { HashLink } from 'react-router-hash-link';
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';

import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Avatar from '@mui/material/Avatar';
import { ThemeProvider, makeStyles } from '@material-ui/styles';

import Faq from './Faq';
import EcosystemDiagram from './EcosystemDiagram';

const pages = ['Products', 'Pricing', 'Blog'];

const AppHeader = (props) => {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const useStyles = makeStyles((theme) => ({
    abRoot: {
      backgroundColor: '#201C37 !important',
      padding: 4.2,
      borderBottom: '1px solid #5C4CB6',
      '& a': {
        color: '#fff',
        letterSpacing: '.2em',
        fontFamily: 'pixeboyregular',
        fontWeight: 'lighter',
        fontSize: '1.13em',
        '&:hover': {
          color: '#DB4261',
        },
      },
      '& button': {
        color: '#DB4261',
      },
    },
  }));
  const classes = useStyles();

  return (
    <AppBar
      position="static"
      classes={{
        root: classes.abRoot,
      }}
    >
      <Container style={{ width: '100%' }} maxWidth="xl">
        <Toolbar disableGutters>
          <Button
            component={Link}
            to="/"
            disableRipple
            onClick={() => props.setValue(0)}
          >
            <img
              src={require('../layout/images/logo.svg').default}
              alt="Fuji Lions"
              width={200}
            />
          </Button>

          <Box
            sx={{
              display: { xs: 'flex', md: 'none' },
              justifyContent: 'flex-end',
              flexGrow: 1,
            }}
          >
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              <MenuItem key="breeding" onClick={handleCloseNavMenu}>
                <HashLink
                  smooth
                  key="breeding"
                  to="/breeding"
                  className="nav-link"
                  onClick={handleCloseNavMenu}
                >
                  Breeding
                </HashLink>
              </MenuItem>
              <MenuItem key="staking" onClick={handleCloseNavMenu}>
                <HashLink
                  smooth
                  key="staking"
                  to="/staking"
                  className="nav-link"
                  onClick={handleCloseNavMenu}
                >
                  Staking
                </HashLink>
              </MenuItem>
              <MenuItem key="roadmap" onClick={handleCloseNavMenu}>
                <HashLink
                  smooth
                  key="roadmap"
                  to="/#roadmap"
                  className="nav-link"
                  onClick={handleCloseNavMenu}
                >
                  Roadmap
                </HashLink>
              </MenuItem>
              <MenuItem key="whitepaper" onClick={handleCloseNavMenu}>
                <HashLink
                  smooth
                  key="whitepaper"
                  to="/#whitepaper"
                  className="nav-link"
                  onClick={handleCloseNavMenu}
                >
                  Whitepaper
                </HashLink>
              </MenuItem>
              <MenuItem key="tokenomics" onClick={handleCloseNavMenu}>
                <HashLink
                  smooth
                  key="tokenomics"
                  to="/#tokenomics"
                  className="nav-link"
                  onClick={handleCloseNavMenu}
                >
                  Tokenomics
                </HashLink>
              </MenuItem>
              <MenuItem key="ecosystem-diagram" onClick={handleCloseNavMenu}>
                <HashLink
                  smooth
                  key="ecosystem-diagram"
                  to="/ecosystem-diagram"
                  className="nav-link"
                  onClick={handleCloseNavMenu}
                >
                  Ecosystem
                </HashLink>
              </MenuItem>
              <MenuItem key="faq" onClick={handleCloseNavMenu}>
                <HashLink
                  smooth
                  key="faq"
                  to="/faq"
                  className="nav-link"
                  onClick={handleCloseNavMenu}
                >
                  FAQ
                </HashLink>
              </MenuItem>
            </Menu>
          </Box>

          <Box
            sx={{
              flexGrow: 1,
              display: { xs: 'none', md: 'flex' },
              paddingTop: 2,
              justifyContent: 'flex-end',
            }}
          >
            <HashLink
              smooth
              key="breeding"
              to="/breeding"
              className="nav-link"
              onClick={handleCloseNavMenu}
            >
              Breeding
            </HashLink>
            <HashLink
              smooth
              key="staking"
              to="/staking"
              className="nav-link"
              onClick={handleCloseNavMenu}
            >
              Staking
            </HashLink>
            <HashLink
              smooth
              key="roadmap"
              to="/#roadmap"
              className="nav-link"
              onClick={handleCloseNavMenu}
            >
              Roadmap
            </HashLink>
            <HashLink
              smooth
              key="whitepaper"
              to="/#whitepaper"
              className="nav-link"
              onClick={handleCloseNavMenu}
            >
              WhitePaper
            </HashLink>
            <HashLink
              smooth
              key="tokenomics"
              to="/#tokenomics"
              className="nav-link"
              onClick={handleCloseNavMenu}
            >
              Tokenomics
            </HashLink>
            <HashLink
              smooth
              key="ecosystem-diagram"
              to="/ecosystem-diagram"
              className="nav-link"
              onClick={handleCloseNavMenu}
            >
              Ecosystem
            </HashLink>
            <HashLink
              smooth
              key="faq"
              to="/faq"
              className="nav-link"
              onClick={handleCloseNavMenu}
            >
              FAQ
            </HashLink>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default AppHeader;

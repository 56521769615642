const wait = (interval: number) =>
  new Promise((resolve) => setTimeout(resolve, interval));

const maxRetryWait = 10 * 1000; // 10s

export async function retry<T>(
  fn: () => Promise<T>,
  retriesLeft = 5,
  interval = 1000
): Promise<T> {
  try {
    return await fn();
  } catch (error) {
    if (retriesLeft === 0) {
      throw error;
    }

    await wait(interval);
    // next retry waits 2*interval or maxRetryWait
    return await retry(fn, --retriesLeft, Math.min(maxRetryWait, interval * 2));
  }
}

import {
  Metadata,
  MetadataData,
} from '@metaplex-foundation/mpl-token-metadata';
import { Connection, PublicKey } from '@solana/web3.js';
import { retry } from './promise';

export interface ArweaveAttribute {
  trait_type: string;
  value: string;
}
export interface ArweaveMetadata {
  name: string;
  description: string;
  image: string;
  attributes: ArweaveAttribute[];
}

export enum FujiType {
  Gen0,
  Newborn,
  Cub,
  DarkCub,
}

const IS_DEVNET = process.env.REACT_APP_IS_DEVNET === 'true';

const GEN0_AUTHORITY = new Set([
  'FujiCoMtbD41YSTkNhKSrQ8n3qjJA3LpPp9GSnU6gLzH',
  'Ced2v4DMAGQDPJAbiqy1m4yTF4p3wmzm38AjwrUVzSoP',
  'GoD1TBL1P4VcenkRAHgMx6zQTNJu21UhJ5zHfFZA6T7E',
]);

const getFujiType = (meta: MetadataData): FujiType => {
  if (GEN0_AUTHORITY.has(meta.updateAuthority)) return FujiType.Gen0;

  const {
    data: { name },
  } = meta;

  if (name.startsWith('Fuji Lion Newborn')) return FujiType.Newborn;
  else if (name.startsWith('Fuji Cub')) return FujiType.Cub;
  else if (name.startsWith('Dark Fuji Cub')) return FujiType.DarkCub;
};

const fujiCreatorAddresses = IS_DEVNET
  ? new Set(['7V5HgodrUb1jebRpFDsxTnYMKvEbMvbpTLn9kCinHPdd'])
  : new Set([
      'L1oNSWtDQ6cyNpLjmD8HCk2GZvdp68HpsjEUqtv3UgH',
      'Ced2v4DMAGQDPJAbiqy1m4yTF4p3wmzm38AjwrUVzSoP',
      'GoD1TBL1P4VcenkRAHgMx6zQTNJu21UhJ5zHfFZA6T7E',
    ]);

export const loadMetaUri = (uri: string) => {
  return retry(() => fetch(uri).then((r) => r.json()));
};

export const getMintedNftMeta = async (
  connection: Connection,
  walletKey: PublicKey
): Promise<[MetadataData, ArweaveMetadata, FujiType][]> => {
  const metas: any[] = [];

  const ownerMeta = await Metadata.findDataByOwner(connection, walletKey);

  for (let meta of ownerMeta) {
    if (
      meta &&
      meta.data &&
      Array.isArray(meta.data.creators) &&
      meta.data.creators.some(
        (creator) =>
          creator.verified && fujiCreatorAddresses.has(creator.address)
      ) &&
      typeof meta.data.uri === 'string'
    ) {
      metas.push(
        loadMetaUri(meta.data.uri)
          .then((response) => [meta, response, getFujiType(meta)])
          .catch(() => null)
      );
    }
  }

  return Promise.all(metas).then((nfts) => nfts.filter(Boolean));
};


import PoweredBy from "./PoweredBy";

const Footer = () => {

  return (
    <div className="footer p-5">
      <div className="container">
        <div className="row">
          <div className="col">
            <p className="text-center ">© 2021 FujiLions</p>
            <PoweredBy />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;

import './App.scss';
import React, { useMemo, useState } from 'react';

import { ThemeProvider } from '@mui/material/styles';

import { BrowserRouter, Routes, Route } from 'react-router-dom';

import AppHeader from './components/AppHeader';
import EcosystemDiagram from './components/EcosystemDiagram';
import Faq from './components/Faq';
import LandingPage from './LandingPage/LandingPage';

import theme from './Theme';
import './layout/App.css';
import {
  createTheme,
  ThemeProvider as MatThemeProvider,
} from '@material-ui/core/styles';
import { Staking } from './pages/staking/Staking';
import { Breeding } from './pages/breeding/Breeding';
import * as anchor from '@project-serum/anchor';
import { SnackbarProvider } from 'notistack';
import {
  ConnectionProvider,
  WalletProvider,
} from '@solana/wallet-adapter-react';
import { WalletDialogProvider } from '@solana/wallet-adapter-material-ui';
import { clusterApiUrl } from '@solana/web3.js';
import {
  getLedgerWallet,
  getMathWallet,
  getPhantomWallet,
  getSlopeWallet,
  getSolflareWallet,
  getSolletWallet,
} from '@solana/wallet-adapter-wallets';
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';

const materialUiTheme = createTheme({
  palette: {
    type: 'dark',
  },
});

const rpcHost = process.env.REACT_APP_SOLANA_RPC_HOST!;
const connection = new anchor.web3.Connection(rpcHost);
const network = process.env.REACT_APP_SOLANA_NETWORK as WalletAdapterNetwork;

const App = () => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [value, setValue] = useState(0);

  const endpoint = useMemo(() => clusterApiUrl(network), []);
  const wallets = useMemo(
    () => [
      getPhantomWallet(),
      getSolflareWallet(),
      getSolletWallet(),
      getSlopeWallet(),
      getLedgerWallet(),
      getMathWallet(),
    ],
    []
  );

  return (
    <ThemeProvider theme={theme}>
      <MatThemeProvider theme={materialUiTheme}>
        <SnackbarProvider maxSnack={3}>
          <ConnectionProvider endpoint={endpoint}>
            <WalletProvider wallets={wallets} autoConnect={true}>
              <WalletDialogProvider>
                <BrowserRouter>
                  <AppHeader
                    value={value}
                    setValue={setValue}
                    selectedIndex={selectedIndex}
                    setSelectedIndex={setSelectedIndex}
                  />
                  <Routes>
                    <Route path="/" element={<LandingPage />} />
                    <Route
                      path="/ecosystem-diagram"
                      element={<EcosystemDiagram />}
                    />
                    <Route path="/faq" element={<Faq />} />
                    <Route
                      path="/staking"
                      element={<Staking connection={connection} />}
                    />
                    <Route
                      path="/breeding"
                      element={<Breeding connection={connection} />}
                    />
                  </Routes>
                </BrowserRouter>
              </WalletDialogProvider>
            </WalletProvider>
          </ConnectionProvider>
        </SnackbarProvider>
      </MatThemeProvider>
    </ThemeProvider>
  );
};

export default App;

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { makeStyles } from '@mui/styles';

import Footer from "./Footer";


const useStyles = makeStyles(theme => ({
  root: {
    "& .MuiPaper-root": {
      backgroundColor: 'transparent',
    },
  },
  cardRoot: {
    background: 'transparent',
    color: "#FFF",
  },
  boxRoot: {
    marginTop: 5,
    color: "#E2E2ED",
  },
  rootContainer: {
    marginBottom: "5em",
  },
}));

const Faq = () => {
  const classes = useStyles();

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container
        className={`${classes.root} bg-page`}
        direction="column"
      >
        <Container className={classes.rootContainer}>
          <Card elevation={0}>
            <CardContent className={classes.cardRoot} >
              <Box sx={{ textAlign: "center", marginTop: 5, marginBottom: 5, color: "#FFF"}}>
                <Typography variant="h1" gutterBottom>
                  FAQ
                </Typography>
              </Box>
              <Paper sx={{
                  color: "#BCCEEF",
                  backgroundColor: '#201E2F !important',
                  padding: 5,
                  textAlign: "left",
                  border: "1px solid #5C4CB6",
                }}
                elevation={5}
              >

                <Box className={classes.boxRoot}>
                  <Typography variant="h3" gutterBottom>
                    What is this? Is this a game? Is it an NFT project?
                  </Typography>
                </Box>
                <Box className="row">
                  <List sx={{ width: '100%', }}>
                    <ListItem alignItems="flex-start">
                      <ListItemIcon style={{ color: "#839AC6" }}>
                        <KeyboardArrowRightIcon />
                      </ListItemIcon>
                      <ListItemText>
                        The Fuji Lions collection is an NFT project that has
                        all the fun of game theory and risk intertwined.
                        With our game theory, certain decisions that you make
                        will impact your success in the Fuji Lions ecosystem.
                        Be careful though, if you make the wrong decision,
                        you risk at losing your precious NFT.
                      </ListItemText>
                    </ListItem>
                  </List>
                </Box>
                <Box className={classes.boxRoot}>
                  <Typography variant="h3" gutterBottom>
                    What is the best strategy?
                  </Typography>
                </Box>
                <Box className="row">
                  <List sx={{ width: '100%', }}>
                    <ListItem alignItems="flex-start">
                      <ListItemIcon style={{ color: "#839AC6" }}>
                        <KeyboardArrowRightIcon />
                      </ListItemIcon>
                      <ListItemText>
                        There is no correct answer to this. The best strategy
                        will continuously change as the project evolves.
                        The most successful players will be the ones that
                        are able to quickly tweak and adapt their strategies
                        depending on what other people are doing in the game.
                      </ListItemText>
                    </ListItem>
                  </List>
                </Box>
                <Box className={classes.boxRoot}>
                  <Typography variant="h3" gutterBottom>
                    Will I be able to buy gen1 later?
                  </Typography>
                </Box>
                <Box className="row">
                  <List sx={{ width: '100%', }}>
                    <ListItem alignItems="flex-start">
                      <ListItemIcon style={{ color: "#839AC6" }}>
                        <KeyboardArrowRightIcon />
                      </ListItemIcon>
                      <ListItemText>
                        There are two ways to buy a gen1 lion. The first way
                        is by owning a gen0 lion and producing
                        enough <span className="pink">$FUJI</span> to
                        breed a gen1 lion using
                        that <span className="pink">$FUJI</span>. The other way is
                        buying an unstaked gen1 lion on a secondary marketplace.
                      </ListItemText>
                    </ListItem>
                  </List>
                </Box>
              </Paper>
            </CardContent>
          </Card>
        </Container>

        <Footer />

      </Grid>
    </Box>
  );
}

export default Faq;

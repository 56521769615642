import * as React from 'react';

import { HashLink } from 'react-router-hash-link';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { makeStyles } from '@mui/styles';
import { ThemeProvider, createTheme, styled } from '@mui/material/styles';

import Footer from "./Footer";


const useStyles = makeStyles(theme => ({
  root: {
    "& .MuiPaper-root": {
      backgroundColor: 'transparent',
    },
    marginBottom: "5em",
  },
  cardRoot: {
    background: 'transparent',
    color: "#FFF",
  },
  boxRoot: {
    marginTop: 5,
    color: "#E2E2ED",
  },
  rootContainer: {
    marginBottom: "5em",
  },
  borderPink: {
    outline: "5px solid #db4161",
    borderColor: "#db4161"
  },
  textItem: {
    color: "#db4161 !important",
    fontFamily: "sansation",
    fontSize: "6em",
    fontWeight: "bold"
  },
  textQuestionMark: {
    color: "#fff !important",
    fontFamily: "sansation",
    fontSize: "6em",
    fontWeight: "bold"
  },
  textBody: {
    fontFamily: "sansation",
  },
}));

const Item = styled(Box)(({ theme }) => ({
  textAlign: 'center',
  color: "#FFF",
}));

function FormRow() {
  return (
    <React.Fragment>
      <Grid item xs={4}>
        <Item>Item</Item>
      </Grid>
      <Grid item xs={4}>
        <Item>Item</Item>
      </Grid>
      <Grid item xs={4}>
        <Item>Item</Item>
      </Grid>
    </React.Fragment>
  );
}

const EcosystemDiagram = () => {
  const classes = useStyles();

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container
        direction="column"
        className={`${classes.root} bg-page` }
      >
        <Container className={classes.rootContainer}>
          <Card elevation={0}>
            <CardContent className={classes.cardRoot} >

              <Box sx={{ textAlign: "center", marginTop: 5, marginBottom: 5, color: "#FFF"}}>
                <Typography variant="h1" gutterBottom>
                  Ecosystem Diagram
                </Typography>
              </Box>

              <Paper sx={{
                  color: "#BCCEEF",
                  backgroundColor: '#201E2F !important',
                  textAlign: "left",
                  border: "1px solid #5C4CB6",
                }}
                elevation={5}
              >

                <Box sx={{ flexGrow: 1, margin: "3em" }}>

                  <Grid container
                    direction="column"
                    columns={12}
                    spacing={0}
                  >
                    <Grid item xs={12}>
                      <Item>
                      <img alt="" height='100%' width='100%'
                        src={require("../layout/images/ecosystem-diagram-2.png").default} />
                      </Item>
                    </Grid>
                    <br/>
                    <br/>
                    <Grid item xs={12}>
                      <Item sx={{ fontSize: ".9em" }} className={classes.textBody}>
                        <Typography variant="h5">
                          * More details in <HashLink smooth to="/#whitepaper">Whitepaper</HashLink>
                        </Typography>
                      </Item>
                    </Grid>
                  </Grid>

                </Box>

              </Paper>

            </CardContent>
          </Card>
        </Container>

        <Footer />

      </Grid>
    </Box>
  );
}

export default EcosystemDiagram;

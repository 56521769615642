import styled from 'styled-components';
import { FC, useCallback, useEffect, useState } from 'react';
import { useWallet } from '@solana/wallet-adapter-react';
import {
  ArweaveMetadata,
  FujiType,
  getMintedNftMeta,
  loadMetaUri,
} from '../../utils/nft';
import { Connection, PublicKey, Transaction } from '@solana/web3.js';
//import {
//  awaitTransactionSignatureConfirmation,
//  calculateRewards,
//  asyncTxs,
//  createStakeTransactions,
//  createUnstakeTransactions,
//  createClaimTransactions,
//  StakedData,
//  testGPA,
//  countStakedNfts,
//} from "../../utils/staking";
import { CircularProgress } from '@mui/material';
import { useSnackbar } from 'notistack';
import { errorCodes } from 'eth-rpc-errors/dist/error-constants';
import { ConnectButton } from '../../components/ConnectButton';
import { dateTimeFormatter, numberFormat } from '../../config';

const programId = new PublicKey('fujiV8AZJKPpzW6fmBbt1RT1zyXxaSWzZbZctRzoPRG');

const Loading = styled.div`
  color: white;
  min-height: 75vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Breeding = ({ connection }: { connection: Connection }) => {
  const { enqueueSnackbar } = useSnackbar();
  const wallet = useWallet();
  const [loading, setLoading] = useState<string | null>(null);
  const [metas, setMetas] = useState<ArweaveMetadata[]>([]);

  const fetchMeta = useCallback(async () => {
    if (!wallet) return;
    if (!wallet.publicKey) return;

    setLoading('Loading FUJIs');
    const metas = await getMintedNftMeta(connection, wallet.publicKey!);

    //    const time_pdas = metas.map(async ([meta, arweave, type]) => {
    //      //
    //      return await PublicKey.findProgramAddress(
    //        [new Uint8Array([98, 105, 114, 116, 104]), gen0_key.toBuffer()],
    //        program_id
    //      )[0];
    //    });

    setLoading(null);
    setMetas(
      metas.map(([meta, arweave, type]) => {
        console.log(meta);
        return arweave;
      })
    );
  }, [wallet]);

  useEffect(() => {
    fetchMeta();
  }, [fetchMeta]);

  const sendInstructions = useCallback(
    async (transaction: Transaction) => {
      return await wallet.sendTransaction(transaction, connection, {
        skipPreflight: true,
      });
    },
    [connection, wallet?.publicKey, wallet.sendTransaction]
  );

  //  const stake = useCallback(
  //  );

  const pageContent = (
    <>
      {loading ? (
        <Loading>
          <div className="flex flex-col items-center">
            <CircularProgress color="inherit" />
            <div className="mt-4">{loading}</div>
          </div>
        </Loading>
      ) : (
        <div className="flex flex-row">
          <div className="max-w-32">
            {metas.map((e) => {
              return (
                <>
                  <span>{e.name}</span>
                </>
              );
            })}
          </div>
          <MintButton />
        </div>
      )}
    </>
  );
  console.log(metas);
  return (
    <>
      {wallet?.publicKey ? (
        <>{pageContent}</>
      ) : (
        <section>
          <div className="container">
            <div className="md:flex items-center flex-row justify-center">
              <ConnectButton>Connect</ConnectButton>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

function MintButton() {
  return <button>Mint</button>;
}

import styled from "styled-components";
import { FC, useCallback, useEffect, useState } from "react";
import { useWallet } from "@solana/wallet-adapter-react";
import {
  ArweaveMetadata,
  FujiType,
  getMintedNftMeta,
  loadMetaUri,
} from "../../utils/nft";
import { Connection, PublicKey, Transaction } from "@solana/web3.js";
import {
  awaitTransactionSignatureConfirmation,
  calculateRewards,
  asyncTxs,
  createStakeTransactions,
  createUnstakeTransactions,
  createClaimTransactions,
  StakedData,
  testGPA,
  countStakedNfts,
} from "../../utils/staking";
import { CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import { errorCodes } from "eth-rpc-errors/dist/error-constants";
import { ConnectButton } from "../../components/ConnectButton";
import { dateTimeFormatter, numberFormat } from "../../config";

const TabDiv = styled.div`
  flex: 1;
  text-align: center;
  padding: 10px;
  text-transform: uppercase;
  transition: background-color 0.2s, color 0.2s;
  cursor: pointer;
`;

const Loading = styled.div`
  color: white;
  min-height: 75vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

enum StakeTab {
  genesisLions = 0,
  regularLions = 1,
  evilLions = 2,
}

const Tab: FC<{ active: boolean; onClick: Function }> = ({
  active,
  onClick,
  children,
}) => {
  return (
    <TabDiv
      role="button"
      onClick={() => onClick()}
      className={
        (active
          ? "text-white font-black bg-gray-800"
          : "text-gray-400 hover:text-white") + " rounded-md"
      }
    >
      {children}
    </TabDiv>
  );
};

const MAX_STAKED_COUNT = 1111;
const TitleSection = ({ stakedNftCount }: { stakedNftCount: number }) => {
  return (
    <>
      <section>
        <div className="container py-5 md:py-20">
          <div className="md:flex items-center flex-row justify-between">
            <h2 className="text-4xl font-semibold">
              <div className="text-white">Staking</div>
            </h2>
            {stakedNftCount !== -1 && (
              <div className="text-2xl font-semibold">
                {stakedNftCount} / {MAX_STAKED_COUNT} staked
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

const TabSection = ({
  selectedTab,
  onTabChange,
}: {
  selectedTab: number;
  onTabChange: (idx: number) => void;
}) => {
  return (
    <section>
      <div className="container">
        <div className="flex items-center flex-row">
          <Tab
            active={selectedTab === StakeTab.genesisLions}
            onClick={() => onTabChange(StakeTab.genesisLions)}
          >
            Genesis Lions
          </Tab>
          <Tab
            active={selectedTab === StakeTab.regularLions}
            onClick={() => onTabChange(StakeTab.regularLions)}
          >
            Regular Lion Cub
          </Tab>
          <Tab
            active={selectedTab === StakeTab.evilLions}
            onClick={() => onTabChange(StakeTab.evilLions)}
          >
            Evil Lion Cub
          </Tab>
        </div>
      </div>
    </section>
  );
};

const NftCardDiv = styled.div`
  border: 1px solid #5c4cb6;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  &.selected {
    background-color: #5c4cb6;
  }
`;

const NftCard: FC<{
  arweaveMetadata: ArweaveMetadata;
  selected: boolean;
  hasSelection: boolean;
}> = ({ hasSelection, arweaveMetadata, selected, children }) => {
  return (
    <>
      <NftCardDiv
        className={
          "p-3 relative " +
          (selected ? "selected" : "") +
          (hasSelection && !selected ? " opacity-50" : "")
        }
        key={arweaveMetadata.name}
      >
        <h2 className="text-white text-1xl font-semibold mb-3 leading-4">
          {arweaveMetadata.name}
        </h2>
        <img className="rounded-md" src={arweaveMetadata.image} alt="" />
        {children}
      </NftCardDiv>
    </>
  );
};

const NftCardButton = styled.button`
  background: #db4261;
  color: #fff;
  border-color: #fff;
  border-radius: 0;
  box-shadow: 2px 3px 0 #201c37;
  display: block;
  padding: 6px 16px;

  &[disabled] {
    opacity: 0.25;
    pointer-events: none;
  }
`;

const NftActionCards: FC<{
  metas: [StakedData, ArweaveMetadata][];
  emptyText: string;
  actionText: string;
  onWithdrawRewardsClick?: (metadata: StakedData) => void;
  onActionClick: (metadata: StakedData) => void;
  selection: string[];
}> = ({
  onWithdrawRewardsClick,
  metas,
  emptyText,
  actionText,
  onActionClick,
  selection,
}) => {
  const now = Math.floor(Date.now() / 1000);

  return (
    <div>
      {metas.length > 0 ? (
        <>
          <div className="grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3">
            {metas
              .sort(([, a], [, b]) => a.name.localeCompare(b.name))
              .map(([metadata, arweaveMeta]) => {
                const canInteract = isUnstakeOrClaimable(metadata);
                return (
                  <NftCard
                    key={metadata.mint}
                    arweaveMetadata={arweaveMeta}
                    hasSelection={selection.length > 0}
                    selected={selection.indexOf(metadata.mint) > -1}
                  >
                    <div className="relative">
                      <div className={canInteract ? "" : "opacity-0"}>
                        <NftCardButton
                          disabled={!canInteract}
                          className="mt-4 block py-2 px-4 text-center w-full rounded-md primary-bg text-white"
                          onClick={() => onActionClick(metadata)}
                        >
                          {actionText}
                        </NftCardButton>
                        {onWithdrawRewardsClick && (
                          <NftCardButton
                            disabled={!canInteract}
                            className="mt-4 block py-2 px-4 text-center w-full rounded-md primary-bg text-white"
                            onClick={() => onWithdrawRewardsClick(metadata)}
                          >
                            Claim{" "}
                            {numberFormat.format(
                              calculateRewards(metadata, now)
                            )}{" "}
                            $FUJI
                          </NftCardButton>
                        )}
                      </div>
                      {!canInteract && (
                        <div className="absolute text-sm inset-0 flex items-center justify-center">
                          Your lion is locked up for staking until{" "}
                          {dateTimeFormatter.format(
                            new Date(metadata.time_staked * 1000 + T24h)
                          )}
                        </div>
                      )}
                    </div>
                  </NftCard>
                );
              })}
          </div>
        </>
      ) : (
        <>
          <div className="text-gray-400 text-center py-20">{emptyText}</div>
        </>
      )}
    </div>
  );
};

const HeaderButton = ({
  disabled = false,
  cb,
  text,
}: {
  disabled?: boolean;
  cb: () => void;
  text: string;
}) => {
  return (
    <>
      <button
        disabled={disabled}
        onClick={() => {
          cb();
        }}
        className={
          "ml-8 text-base border-2 pl-2 pr-2 border-white rounded-sm cursor-pointer select-none " +
          (disabled
            ? "opacity-50 cursor-not-allowed"
            : "opacity-90 hover:opacity-100")
        }
      >
        {text}
      </button>
    </>
  );
};

const SelectAll = ({
  metas,
  actions,
  selected,
  onSelect,
  onDeselect,
  disabled = false,
  selectText = "Select All",
}: {
  metas: [StakedData, ArweaveMetadata][];
  actions: JSX.Element;
  selected: string[];
  onSelect: () => void;
  onDeselect: () => void;
  disabled: boolean;
  selectText?: string;
}) => {
  const hasSelection = selected.length > 0;

  return (
    <>
      <HeaderButton
        cb={hasSelection ? onDeselect : onSelect}
        text={hasSelection ? "Deselect All" : selectText}
        disabled={disabled}
      />
      {actions}
    </>
  );
};

const T24h = 1000 * 60 * 60 * 24;
//const T24h = 10 * 1000;
const isUnstakeOrClaimable = (meta: StakedData) => {
  if (meta.time_staked === -1) return true;

  return Date.now() - meta.time_staked * 1000 > T24h;
};

const NftSection = ({
  metas,
  stake,
  stakedMetas,
  unstake,
  rewards,
  withdrawRewards,
}: {
  rewards: number;
  metas: [StakedData, ArweaveMetadata][];
  stakedMetas: [StakedData, ArweaveMetadata][];
  stake: (mints: string[], mint_type: number) => unknown;
  unstake: (mints: string[], mint_type: number) => unknown;
  withdrawRewards: (mints: string[], mint_type: number) => unknown;
}) => {
  const [staked_selection, setStakedSelection] = useState<string[]>([]);
  const [unstaked_selection, setUnstakedSelection] = useState<string[]>([]);

  const getStakableLions = () => {
    return metas
      .filter(([data]) => data.is_lion === true)
      .map(([data]) => data.mint);
  };
  const getUnstakableLions = () => {
    return stakedMetas
      .filter(([meta]) => isUnstakeOrClaimable(meta))
      .map(([meta]) => meta.mint);
  };

  const unstakedActions = (
    <>
      <HeaderButton
        disabled={unstaked_selection.length === 0}
        text="Stake"
        cb={() => {
          stake(unstaked_selection, 0);
        }}
      />
    </>
  );

  const stakedActions = (
    <>
      <HeaderButton
        text="Claim"
        disabled={staked_selection.length === 0}
        cb={() => {
          withdrawRewards(staked_selection, 0);
        }}
      />
      <HeaderButton
        text="Unstake"
        disabled={staked_selection.length === 0}
        cb={() => {
          unstake(staked_selection, 0);
        }}
      />
    </>
  );

  return (
    <>
      <section id="my-nfts">
        <div className="container pb-5">
          <div className="text-3xl font-semibold mb-4 sm:flex flex-row items-center">
            <h2>Unstaked</h2>
            <SelectAll
              metas={metas}
              actions={unstakedActions}
              selected={unstaked_selection}
              disabled={getStakableLions().length === 0}
              onSelect={() => {
                setUnstakedSelection(getStakableLions());
              }}
              onDeselect={() => {
                setUnstakedSelection([]);
              }}
            />
          </div>
          <div className="mb-4">
            <NftActionCards
              selection={unstaked_selection}
              metas={metas.filter(([m]) => true || m.is_lion)}
              actionText="Stake"
              emptyText={"You don't have any unstaked NFTs 😟"}
              onActionClick={(metadata) => {
                stake([metadata.mint], 0);
              }}
            />
          </div>

          <div className="text-3xl font-semibold pt-4 mb-4 sm:flex flex-row items-center">
            <h2>Staked</h2>
            <SelectAll
              metas={metas}
              actions={stakedActions}
              selected={staked_selection}
              selectText="Select All Eligible"
              disabled={getUnstakableLions().length === 0}
              onSelect={() => {
                setStakedSelection(getUnstakableLions());
              }}
              onDeselect={() => {
                setStakedSelection([]);
              }}
            />
          </div>
          <div className="mb-4">
            <NftActionCards
              selection={staked_selection}
              metas={stakedMetas.filter(([m]) => true || m.is_lion)}
              actionText="Unstake"
              emptyText={"You don't have any staked NFTs 😟"}
              onActionClick={(metadata) => {
                unstake([metadata.mint], 0);
              }}
              onWithdrawRewardsClick={(metadata) => {
                withdrawRewards([metadata.mint], 0);
              }}
            />
          </div>
        </div>
      </section>
    </>
  );
};

export const Staking = ({ connection }: { connection: Connection }) => {
  const { enqueueSnackbar } = useSnackbar();
  const wallet = useWallet();
  const [selectedTab, setSelectedTab] = useState(StakeTab.genesisLions);
  const [loading, setLoading] = useState<string | null>(null);
  const [rewards, setRewards] = useState<number>(0);
  const [stakedNftCount, setStakedNftCount] = useState<number>(-1);
  const [metas, setMetas] = useState<[StakedData, ArweaveMetadata][]>([]);
  const [stakedMetas, setStakedMetas] = useState<
    [StakedData, ArweaveMetadata][]
  >([]);

  const fetchMeta = useCallback(async () => {
    if (!wallet) return;
    if (!wallet.publicKey) return;

    setLoading("Loading FUJIs");
    const metas = await getMintedNftMeta(connection, wallet.publicKey!);
    const gpa = await testGPA(connection, wallet.publicKey.toBase58());

    setStakedNftCount(await countStakedNfts(connection));

    const stakedData = (
      await Promise.all(
        gpa.map((data) =>
          loadMetaUri(data.uri)
            .then(
              (arweaveData) =>
                [data, arweaveData] as [StakedData, ArweaveMetadata]
            )
            .catch(() => null)
        )
      )
    ).filter(Boolean);
    // setRewards(
    //   stakedData.reduce(
    //     (sum, [staked]) => sum + calculateRewards(staked, new Date()),
    //     0
    //   )
    // );
    setStakedMetas(stakedData);
    setLoading(null);
    setMetas(
      metas.map(([meta, arweave, type]) => [
        {
          mint: meta.mint,
          is_cub: type === FujiType.Cub,
          is_dark: type === FujiType.DarkCub,
          is_newborn: type === FujiType.Newborn,
          is_lion: type === FujiType.Gen0,
          time_last_fed: -1,
          time_staked: -1,
          local_claim: -1,
          uri: meta.data.uri,
        },
        arweave,
      ])
    );
  }, [wallet]);

  useEffect(() => {
    fetchMeta();
  }, [fetchMeta]);

  const sendInstructions = useCallback(
    async (transaction: Transaction) => {
      return await wallet.sendTransaction(transaction, connection, {
        skipPreflight: true,
      });
    },
    [connection, wallet?.publicKey, wallet.sendTransaction]
  );

  const handleStakeUnStakeError = (message: string, error: any) => {
    if (error?.error?.code === errorCodes.provider.userRejectedRequest) return;
    const errorMessage = error?.message ?? undefined;
    enqueueSnackbar(
      `Sorry there was an error when staking your NFT. Please try again. ${
        errorMessage ? `Reason: ${errorMessage}` : ""
      }`,

      {
        variant: "error",
      }
    );
  };

  const stake = useCallback(
    async (unstaked_selection: string[], mint_type: number) => {
      try {
        const txs = await createStakeTransactions(
          unstaked_selection,
          wallet.publicKey,
          mint_type,
          connection
        );
        setLoading("Waiting for staking confirmation");
        await asyncTxs(txs, connection, wallet);
        enqueueSnackbar("Staked successfully", {
          variant: "success",
        });
        await fetchMeta();
        setLoading(null);
      } catch (error: any) {
        setLoading(null);
        handleStakeUnStakeError(
          "Sorry there was an error when staking. Please try again.",
          error
        );
      }
    },
    [connection, wallet?.publicKey, sendInstructions]
  );

  const unstake = useCallback(
    async (unstaked_selection: string[], mint_type: number) => {
      if (!wallet.publicKey) return;
      try {
        const txs = await createUnstakeTransactions(
          unstaked_selection,
          wallet.publicKey,
          mint_type,
          connection
        );
        setLoading("Waiting for unstaking confirmation");
        await asyncTxs(txs, connection, wallet);
        enqueueSnackbar("Unstaked successfully", {
          variant: "success",
        });
        await fetchMeta();
        setLoading(null);
      } catch (error: any) {
        setLoading(null);
        handleStakeUnStakeError(
          "Sorry there was an error when unstaking. Please try again.",
          error
        );
      }
    },
    [connection, wallet.publicKey, sendInstructions]
  );

  const withdrawRewards = useCallback(
    async (unstaked_selection: string[], mint_type: number) => {
      if (!wallet.publicKey) return;
      try {
        const txs = await createClaimTransactions(
          unstaked_selection,
          wallet.publicKey,
          mint_type,
          connection
        );
        setLoading("Waiting for unstaking confirmation");
        await asyncTxs(txs, connection, wallet);
        enqueueSnackbar("Unstaked successfully", {
          variant: "success",
        });
        await fetchMeta();
        setLoading(null);
      } catch (error: any) {
        setLoading(null);
        handleStakeUnStakeError(
          "Sorry there was an error when unstaking. Please try again.",
          error
        );
      }
    },
    [connection, wallet.publicKey, sendInstructions]
  );

  const pageContent = (
    <>
      {loading ? (
        <Loading>
          <div className="flex flex-col items-center">
            <CircularProgress color="inherit" />
            <div className="mt-4">{loading}</div>
          </div>
        </Loading>
      ) : (
        <NftSection
          metas={metas}
          stakedMetas={stakedMetas}
          stake={stake}
          unstake={unstake}
          rewards={rewards}
          withdrawRewards={withdrawRewards}
        />
      )}
    </>
  );

  return (
    <>
      <TitleSection stakedNftCount={stakedNftCount} />
      {wallet?.publicKey ? (
        <>
          {false && (
            <TabSection
              selectedTab={selectedTab}
              onTabChange={(idx) => setSelectedTab(idx)}
            />
          )}
          {pageContent}
        </>
      ) : (
        <section>
          <div className="container">
            <div className="md:flex items-center flex-row justify-center">
              <ConnectButton>Connect</ConnectButton>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

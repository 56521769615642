const ImageHomies = "/img/logo.svg";

const PoweredBy = () => {
  return (
    <div className="text-gray-400 mb-5">
      <div className="flex justify-center items-center text-base">
        <span className="mr-2">powered by</span>
        <a href="https://twitter.com/ninehomies" target="_blank">
          <img className="h-4" src={ImageHomies} alt="9HOMIES" />
        </a>
      </div>
    </div>
  );
};

export default PoweredBy;
